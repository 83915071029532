<template>
<v-card color="grey lighten-4" flat>
    <v-toolbar color="green" dark class="elevation-2" height="50px">
        <v-icon class="mr-3">mdi-chevron-down</v-icon>
        <v-toolbar-title>{{ cardTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="" @click.native="switchModal('trackingzone', 0, 0)"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
        <v-form ref="userForm" v-model="validForm" lazy-validation>
            <v-layout row wrap>
            <v-flex xs6 class="pa-2">
                <auto-multi v-if="!loading.getItem" :title="'Contact'" ressource="contacts" :listObject.sync="autoComplete.dia_ctt_id_mandat_org"
                    :listParent.sync="autoCompleteSource.cto" :fields="{ index: 'ctt_id', text:'ctt_middlename', search:'ctt_middlename', multiSelect:false }"
                    class="ml-2 mr-2" :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )">
                </auto-multi>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <auto-multi v-if="!loading.getItem" :title="'Société / Entreprise'" ressource="contacts" :listObject.sync="autoComplete.dia_ctt_id_press"
                    :listParent.sync="autoCompleteSource.cta" :fields="{ index: 'ctt_id', text:'ctt_middlename', search:'ctt_middlename', multiSelect:false }"
                    class="ml-2 mr-2" :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )">
                </auto-multi>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-select 
                    :items="typeItem" v-model="objItem.dia_typ_id" label="Type de zone"
                    item-text="typ_name" item-value="typ_id" :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )">
                </v-select>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Cout" v-model="objItem.dia_cost" required :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-checkbox v-model="objItem.dia_approved" label="Approuvé" :value="1" :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )"></v-checkbox>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <DatePicker v-if="!loading.getItem" :label="'Date approbation'" :objectForm="objItem" fieldName='dia_approval_date' :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )"></DatePicker>
            </v-flex>
            <v-flex xs12 class="pa-2">
                <v-text-field label="Observations" v-model="objItem.dia_observation" required :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            </v-layout>
        </v-form>
    </v-card-text>
    <v-card-actions style="background-color:white;">
        <v-btn color="error" v-if="!loading.getItem && itemId > 0 && isAuthorized( 'TrackingSheetEcology', 'store' )" :loading="saveProgress" @click.native="deleteItem()" :disabled="!validForm">
            <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" v-if="!loading.getItem && isAuthorized( 'TrackingSheetEcology', 'store' )" :loading="saveProgress" @click.native="saveItem()" :disabled="!validForm">Enregistrer</v-btn>
        <v-btn color="primmary" @click.native="switchModal('trackingzone', 0, 0)">Retour</v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
import autoCompMulti    from './Autocomplete_multi.vue'
import { cacheGetters } from "../store/cache.module"
import { usrGetters }   from "../store/user.module"
import DatePicker from '@/components/ui/DatePicker'

export default {
    name: 'edit_diagnostic',
    props: ['switchModal', 'objTrackingSheet', 'itemId'],
    components: {
        DatePicker, 'auto-multi': autoCompMulti
    },
    data: function () {
        return {
            isAuthorized: usrGetters.getScopeRight,
            cardTitle: 'Diagnostic',
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            loading: { getItem: true },
            validForm   : true,
            typeItem: cacheGetters.getTypes('DIA'),
            objItem: { 
                dia_id: this.itemId, dia_trs_id: this.objTrackingSheet.trs_id, dia_typ_id: 'DIA01',
                dia_ctt_id_mandat_org: null, dia_ctt_id_press: null, dia_approval_date: null, dia_approved: 0,
                dia_observation: '', dia_cost: 0,
                author: {}, organization: {}
            },
            autoComplete: {
                dia_ctt_id_mandat_org: null,
                dia_ctt_id_press: null,
            },
            autoCompleteSource:{
                cto: [],
                cta: [],
            },
            dateMenu : {},
            nameRules: [
                v => !!v || 'Ce champ est obligatoire',
                v => v.length <= 250 || 'Le champ doit avoir moins de 255 charactères'
            ],
        }
    },
    mounted:function (){
        if( this.itemId > 0){
            this.getItem()
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
        getItem(){
            return new Promise( (resolve, reject) => {
                this.items = []
                this.loading.getItem = true
                this.$http.get( '/diagnostics/' + this.itemId + '/?include=author,organization' ).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( typeof response.data.data[prop] === 'object' && response.data.data[prop] !== null && response.data.data[prop]['data'] !== undefined ){
                            this.objItem[prop] = response.data.data[prop]['data']
                        } else {
                            this.objItem[prop] = response.data.data[prop]
                        }
                    }
                    if( this.objItem.organization ){
                        this.autoCompleteSource.cto.push(this.objItem.organization)
                        this.autoComplete.dia_ctt_id_mandat_org = this.objItem.dia_ctt_id_mandat_org
                    }
                    if( this.objItem.author ){
                        this.autoCompleteSource.cta.push(this.objItem.author)
                        this.autoComplete.dia_ctt_id_press = this.objItem.dia_ctt_id_press
                    }
                    this.loading.getItem = false
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        saveItem() {
            this.objItem.dia_ctt_id_mandat_org = this.autoComplete.dia_ctt_id_mandat_org || null
            this.objItem.dia_ctt_id_press      = this.autoComplete.dia_ctt_id_press  || null
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.itemId > 0 ){
                this.$http.put( '/diagnostics/' + this.itemId, this.objItem ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('diagnostic', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error })
                    this.switchModal('diagnostic', 0, 1)
                })
            } else {
                this.$http.post( '/diagnostics', this.objItem ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('diagnostic', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ 
                        color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error 
                    })
                    this.switchModal('diagnostic', 0, 0)
                });
            }
        },
        deleteItem(){
            if( this.itemId > 0 ){
                this.$http.delete( '/diagnostics/' + this.itemId ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée' })
                    this.switchModal('diagnostic', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de la suppression !\n' + error })
                    this.switchModal('diagnostic', 0, 1)
                })
            }
        }
    },
}
</script>