<template>
<v-sheet fluid class="elevation-1 pa-0 pb-2 overflow-y-auto" :style="'height: 100%; padding-top:' + infoPadding + ' !important;'">
    <v-layout ref="trsInfo" wrap style="background-color:white; position:relative; position:fixed; top:56px; width:100%; z-index:100;" class="pa-2 elevation-1">
        <v-flex xs5 style="border-right:1px solid #E0E0E0;">
            <v-list dense height="12">
                <template v-for="(itemLeft, indexLeft) in blockObj.info.data['left']">
                <v-list-item dense :key="indexLeft">
                    <v-list-item-content>
                        <v-list-item-title>{{ itemLeft.text }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="itemLeft.mod == 'chip'" class="ma-0">
                        <v-chip v-if="itemLeft.value !== 0" :color="(itemRight.color) ? itemRight.color : 'primary'" small label outlined>{{ itemLeft.value }} {{ (itemLeft.unit) || '' }}</v-chip>
                        <v-icon v-else light :title="itemLeft.title">mdi-information-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-action v-else class="ma-0">
                        <v-list-item-action-text>
                            {{ (itemLeft.switch) ? itemLeft.value2 : itemLeft.value }}
                            <v-icon v-show="(itemLeft.switch !== undefined) && (itemLeft.value.length)" @click="itemLeft.switch = !itemLeft.switch">mdi-swap-vertical</v-icon>
                        </v-list-item-action-text>
                    </v-list-item-action>
                </v-list-item>
                </template>
            </v-list>
        </v-flex>
        <v-flex xs5 >
            <v-list dense>
                <template v-for="(itemRight, indexRight) in blockObj.info.data['right']">
                <v-list-item dense :key="indexRight">
                    <v-list-item-content>
                        <v-list-item-title>{{ itemRight.text }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="itemRight.mod == 'chip'" class="ma-0">
                        <v-chip v-if="itemRight.value" :color="(itemRight.color) ? itemRight.color : 'primary'" small label outlined>{{ itemRight.value }}</v-chip>
                        <v-icon v-else light :title="itemRight.title">mdi-information-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-action v-else class="ma-0">
                        <v-list-item-action-text>{{ itemRight.value }}</v-list-item-action-text>
                    </v-list-item-action>
                </v-list-item>
                </template>
            </v-list>
        </v-flex>
        <v-flex xs2 >
            <v-list dense>
            <v-list-item dense v-if="(!routeState.edit)" class="pt-0 pb-0"><v-list-item-content class="pt-0 pb-0">
                <v-btn small outlined light color="primary" @click="goToMap()" >Carte</v-btn>
            </v-list-item-content></v-list-item>
            <v-list-item dense v-if="(!routeState.edit)" class="pt-0 pb-0"><v-list-item-content class="pt-0 pb-0">
                <v-menu transition="slide-y-transition" offset-y bottom style="z-index:2500" v-if="isAuthorized( 'TrackingSheet', 'update' )">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small outlined light :color="'purple'" v-bind="attrs" v-on="on">
                            <v-icon left>mdi-chevron-down</v-icon>Action
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item :loading="loading.reportSheet" dense v-if="(!routeState.edit)"><v-list-item-content>
                            <v-btn v-if="isAuthorized( 'TrackingSheet', 'update' )" block small outlined light :color="'primary'" :href="'/#/site/' + this.objTrackingSheet.trs_id">Editer la fiche</v-btn>
                        </v-list-item-content></v-list-item>
                        <v-list-item dense v-if="(!routeState.edit)"><v-list-item-content>
                            <v-btn v-if="isAuthorized( 'TrackingSheet', 'update' )" block small outlined light :color="'orange'" @click="closeSheet()">Archiver la fiche</v-btn>
                        </v-list-item-content></v-list-item>
                        <!-- <v-list-item dense v-if="(!routeState.edit)"><v-list-item-content>
                        <v-btn v-if="isAuthorized( 'TrackingSheet', 'destroy' )" block small outlined light :color="'red'" @click="deleteSheet()">Supprimer la fiche</v-btn>
                        </v-list-item-content></v-list-item>-->
                    </v-list>
                </v-menu>
            </v-list-item-content></v-list-item>
            <v-list-item dense v-if="(!routeState.edit)" class="pt-0 pb-0"><v-list-item-content class="pt-0 pb-0">
                <v-btn small outlined light color="orange" @click="downloadSheet()" >
                    <v-icon left>mdi-file-excel-outline</v-icon>Télécharger
                </v-btn>
            </v-list-item-content></v-list-item>
            <v-list-item v-if="(routeState.edit)"><v-list-item-content>
                <v-btn small outlined light :color="'green'" @click="saveSite()">
                    Enregistrer
                </v-btn>
            </v-list-item-content></v-list-item>
            <v-list-item v-if="(routeState.edit)"><v-list-item-content>
                <v-btn small outlined light :color="'grey'" v-show="(routeState.edit)" @click="cleanTrackingSheetObj()">
                    Rétablir
                </v-btn>
            </v-list-item-content></v-list-item>
            </v-list>
        </v-flex>
    </v-layout>

    <!-- Associative Tab -->
    <v-card fluid class="ma-2 pa-0 elevation-1">
        <v-divider></v-divider>
        <v-tabs v-model="activeTab" grow light show-arrows="mobile">
            <v-tabs-slider color="green"></v-tabs-slider>
            <!-- <v-tab><v-icon class="mr-1">mdi-file-document-box-check</v-icon><span class="hidden-sm-and-down">Procédures</span></v-tab> -->
            <v-tab class="pl-1 pr-1" v-show="isAuthorized( 'TrackingSheetEcology', 'show' )"><v-icon title="Ecologie"  class="hidden-md-and-up mr-1">mdi-tree</v-icon><span class="hidden-sm-and-down">écologie</span></v-tab>
            <v-tab class="pl-1 pr-1" v-show="isAuthorized( 'TrackingSheetDocuments', 'show' )"><v-icon title="Documents" class="hidden-md-and-up mr-1 mr-1">mdi-paperclip</v-icon><span class="hidden-sm-and-down">Documents</span></v-tab>
            <v-tab class="pl-1 pr-1" v-show="isAuthorized( 'TrackingSheetAdvice', 'show' )"><v-icon title="Avis"      class="hidden-md-and-up mr-1 mr-1">mdi-comment-check-outline</v-icon><span class="hidden-sm-and-down">Avis</span></v-tab>
            <v-tab class="pl-1 pr-1" v-show="isAuthorized( 'TrackingSheetEvent', 'show' )"><v-icon title="Évènements" class="hidden-md-and-up mr-1 mr-1">mdi-calendar-outline</v-icon><span class="hidden-sm-and-down">évènements</span></v-tab>
            <v-tab class="pl-1 pr-1" v-show="isAuthorized( 'TrackingSheetEvent', 'show' )"><v-icon title="Contrôles" class="hidden-md-and-up mr-1 mr-1">mdi-calendar-outline</v-icon><span class="hidden-sm-and-down">contrôles</span></v-tab>
            <v-tab class="pl-1 pr-1" v-show="isAuthorized( 'TrackingSheetConvention', 'show' )"><v-icon title="Convention" class="hidden-md-and-up mr-1 mr-1">mdi-script-text-outline</v-icon><span class="hidden-sm-and-down">{{ (objTrackingSheetInfo.typeContract == 'AQU') ? 'Plan de gestion' : 'Convention'}}</span></v-tab>
            <v-tab class="pl-1 pr-1" v-show="isAuthorized( 'TrackingSheetLanddata', 'show' )"><v-icon title="Données foncières" class="hidden-md-and-up mr-1 mr-1">mdi-information-variant</v-icon><span   class="hidden-sm-and-down">Données foncières</span></v-tab>
            <!-- <v-tab class="pl-1 pr-1" v-show="(objTrackingSheetInfo.typeContract == 'COV') && isAuthorized( 'TrackingSheetBilling', 'show' )"><v-icon title="Facturation" class="hidden-md-and-up mr-1 mr-1">mdi-receipt</v-icon><span class="hidden-sm-and-down">Facturation</span></v-tab> -->
        </v-tabs>
        <v-divider></v-divider>
        <!-- <procedure-tab v-if="activeTab === 0 && showTab == true" :objTrackingSheet.sync="objTrackingSheet"  :table_id="'trs'" :routeState="routeState" ></procedure-tab> -->
        <ecology-tab   v-if="activeTab === 0 && showTab == true && isAuthorized( 'TrackingSheetEcology', 'show' )"   :objTrackingSheet.sync="objTrackingSheet"   :table_id="'trs'" :routeState="routeState" ></ecology-tab>
        <tab-doc       v-if="activeTab === 1 && showTab == true && isAuthorized( 'TrackingSheetDocuments', 'show' )" :doc_xxx_id="objTrackingSheet.trs_id"       :table_id="'trs'" :routeState="routeState" ></tab-doc>
        <view-tab      v-if="activeTab === 2 && showTab == true && isAuthorized( 'TrackingSheetAdvice', 'show' )"    :objTrackingSheet.sync="objTrackingSheet"   :table_id="'trs'" :routeState="routeState" ></view-tab>
        <event-tab     v-if="activeTab === 3 && showTab == true && isAuthorized( 'TrackingSheetEvent', 'show' )"     :eve_xxx_id.sync="objTrackingSheet.trs_id"  :table_id="['trs', 'tcv', 'spf']" :routeState="routeState" :title="'Évènements'" :typfilter="'MES'"></event-tab>
        <control-tab   v-if="activeTab === 4 && showTab == true && isAuthorized( 'TrackingSheetEvent', 'show' )"     :eve_xxx_id.sync="objTrackingSheet.trs_id"  :table_id="'trs'" :routeState="routeState" :title="'Contrôles'" :typfilter="'CTR'" :sheetType="objTrackingSheet.trs_typ_id"></control-tab>
        <conv-tab      v-if="activeTab === 5 && showTab == true && isAuthorized( 'TrackingSheetConvention', 'show' )" :objTrackingSheet.sync="objTrackingSheet" :table_id="'trs'" :routeState="routeState" ></conv-tab>
        <landed-tab    v-if="activeTab === 6 && showTab == true && isAuthorized( 'TrackingSheetLanddata', 'show' )"  :objTrackingSheet.sync="objTrackingSheet"  :objTrackingSheetInfo.sync="objTrackingSheetInfo" :routeState="routeState" :table_id="'trs'"></landed-tab>
        <!--  <bil-tab       v-if="activeTab === 7 && showTab == true && isAuthorized( 'TrackingSheetBilling', 'show' )" :objTrackingSheet.sync="objTrackingSheet"   :table_id="'trs'" :routeState="routeState"></bil-tab> -->
    </v-card>
</v-sheet>
</template>

<script>
import dateLocale   from '../services/dateLocale';
//import procedureTab from '../components/Tab_procedure.vue'
import eventTab     from '../components/Tab_event.vue'
import documentTab  from '../components/Tab_document.vue'
import ecologyTab   from '../components/Tab_ecology.vue'
import viewTab      from '../components/Tab_view.vue'
import convTab      from '../components/Tab_convention.vue'
//import billingTab   from '../components/Tab_billing.vue'
import landedTab    from '../components/Tab_landed.vue'
import { usrGetters }   from "../store/user.module"
//import aquiTab      from '../components/Tab_aquisition.vue'
import { trsObj }       from '../mixins/trs_obj.js'
import { cacheGetters, cacheActions, cacheMutations } from "../store/cache.module"
import { trackingsheetUpdateService } from '@/rxjsServices';

export default {
    name: 'view_trackingsheet',
    props: ['link', 'objSite', 'routeState'],
    mixins: [ trsObj ],
    data: function () {
        return {
            trsLoaded: false,
            loading: { getSite: false, reportSheet:false },
            locDateFormat: dateLocale, // Format de date
            infoPadding: '50px',
            activeTab: 0, 
            showTab: false,
            blockObj: {
                info: { data: { 'left': [], 'right': [] }, 'left': '', 'right': '' },
            },
            card_obj: {},
            isAuthorized: usrGetters.getScopeRight
        }
    },
    mounted: function (){
        this.trsService = trackingsheetUpdateService.getTrackingsheet().subscribe(message => {
            if (message == 'update') {
              this.getSite()
            }
        })

        if( this.$route.params.trs_id !== undefined && this.$route.params.trs_id > 0 ){
            this.objTrackingSheet.trs_id = this.$route.params.trs_id
        }
        this.getSite()
    },
    computed: {

    },
    methods: {
        async getSite(){
            await this.getTrackingSheetShow('town,watermass,address')
            await this.getTrackingStateIndex()
            this.blockObj.info.data['left']  = []
            this.blockObj.info.data['right'] = []

            this.blockObj.info.data['left'].push( { text: 'Identifiant', value: this.objTrackingSheet.trs_mc2_code, switch: true, text2: 'Coddt', value2: this.objTrackingSheet.trs_coddt } )
            this.blockObj.info.data['left'].push( { text: 'Nom', value: this.objTrackingSheet.trs_name } )
            this.blockObj.info.data['left'].push( { text: 'Ville', value: this.objTrackingSheet._info.town.tow_name + ' (' + this.objTrackingSheet.dep_name + ')' } )
            this.blockObj.info.data['right'].push( 
                { text: 'Modification', value: this.locDateFormat.toLocale(this.objTrackingSheet.trs_updated_at, { year: 'numeric', month: 'long', day: 'numeric' }) }
            )
            this.blockObj.info.data['right'].push( { text: 'Surface', value: this.objTrackingSheet.trs_area_plot + ' ha' })
            let typName  = this.getCacheType('SIT', this.objTrackingSheet.trs_typ_id).typ_name
            let typColor = ( this.objTrackingSheet.trs_sta_id == 'GEN01' ) ? this.getCacheType('SIT', this.objTrackingSheet.trs_typ_id).typ_color : 'grey'
            this.blockObj.info.data['right'].push( { text: 'Maitrise foncière', value: typName, mod:'chip', color:typColor })

            await this.getTrackingContactIndex()
            //this.blockObj.info.data['right'].push( {text: 'Référant', value: this.objTrackingSheet._data['contacts']['TSC20'].map( (elem) => { return elem.ctt_middlename; }).join(",")})
            
            this.showTab = true
            this.cloneTrackingSheetObj()
            setTimeout( () => {
                this.caclInfoPadding()
            }, 500)

        },
        saveSite(){
            this.saveTrackingSheet().then( () => {
                this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche Enregistré !' })
            })
        },
        closeSheet(){
            this.$root.$confirm('Cloturer', 'Confirmer la fermeture de cette fiche', { color: 'red', width: 500, addDate: true }).then((confirm) => {
                if( confirm ){
                    this.objTrackingSheet.trs_sta_id = "GEN04"
                    this.objTrackingSheet.trs_date_archive = confirm.date
                    this.putTrackingSheetStore().then( () => {
                        this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche clôturée !' })
                        this.$router.push({ path: '/trackingsheet' })
                    })
                }
            })
        },
        deleteSheet(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.objTrackingSheet.trs_sta_id = "GEN05"
                    this.putTrackingSheetStore().then( () => {
                        this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée !' })
                        this.$router.push({ path: '/trackingsheet' })
                    })
                }
            })
        },
        caclInfoPadding () {
            if( !this.$refs.trsInfo ){
                this.infoPadding = '120px'
            } else {
                this.infoPadding = this.$refs.trsInfo.offsetHeight + 56 + 'px'
            }
        },
        goToMap(){
            let lastSheet = JSON.parse( JSON.stringify(this.objTrackingSheet))
            cacheMutations.setLastSheet(lastSheet)
            cacheActions.storeCacheToStorage()
            this.$router.push( '/map' )
        },
        downloadSheet(){
            this.$http.customRequest({ method: 'get', url: '/report/trackingsheet/' + this.objTrackingSheet.trs_id, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "rapport_1.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            })
        },
        getCacheType: cacheGetters.getTypes
    },
    components: {
        //'procedure-tab': procedureTab,
        'ecology-tab': ecologyTab,
        'event-tab': eventTab,
        'control-tab': eventTab,
        'tab-doc' : documentTab,
        'view-tab': viewTab,
        'conv-tab': convTab, //'aqui-tab': aquiTab,
        //'bil-tab': billingTab,
        'landed-tab': landedTab
    },
    beforeDestroy() {
        this.trsService
    }
}
</script>