<template>
<v-container fluid class="pa-3">
    <v-layout wrap>
    <v-flex xs12 class="pl-2 pr-2 pb-1">
        <v-expansion-panels>
            <v-expansion-panel expand>
                <v-expansion-panel-header disable-icon-rotate>
                    <template v-slot:actions>
                        <v-icon color="primary">$vuetify.icons.expand</v-icon>
                    </template>
                    <template v-slot:default>
                        <div><span class="font-weight-bold">Procédure</span></div>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                <v-layout row wrap>
                    <code class="pa-2 caption font-italic theme--dark primary v-toolbar" style="color:white; width:100%;">Mesure de compensation proposée au titre des procédures administratives suivantes</code>
                    <v-flex xs12 class="pa-2" >
                        <v-simple-table :dense="false" :fixed-header="false">
                        <template v-slot:default>
                            <thead>
                                <th v-for="(item, index) in noticeHeaders" :key="index" class="text-left" :style="'width:'+ item.width +'%;'">
                                    {{item.text}}
                                </th>
                            </thead>
                            <tbody>
                                <tr v-for="(prop, pindex) in noticeProp" :key="pindex">
                                    <td v-for="(item, nindex) in noticeHeaders" :key="nindex" class="checkboxfull">
                                        <v-flex v-if="noticeHeaders[nindex].type == 'text'">
                                            {{ typeState['TST'][prop].typ_name }}
                                        </v-flex>
                                        <v-flex v-else-if="noticeHeaders[nindex].type == 'date'">
                                            <v-menu style="z-index:1500" :disabled="routeState.view"
                                            :ref="'date' + prop" :close-on-content-click="false" v-model="noticeDate[prop]"
                                            :return-value.sync="objTrackingSheet._data['trackingstates'][prop].tst_date"
                                            transition="scale-transition" offset-y min-width="290px" >
                                            <template v-slot:activator="{ on }">
                                            <v-chip small outlined color="green" v-on="on">
                                                <span v-if="objTrackingSheet._data['trackingstates'][prop].tst_date">
                                                    {{ locDateFormat.toLocale(objTrackingSheet._data['trackingstates'][prop].tst_date) }}
                                                </span>
                                                <v-icon v-else color="green">mdi-calendar-edit</v-icon>
                                            </v-chip>
                                            </template>
                                            <v-date-picker 
                                                @input="$refs['date' + prop][0].save(objTrackingSheet._data['trackingstates'][prop].tst_date)"
                                                locale="fr" v-model="objTrackingSheet._data['trackingstates'][prop].tst_date">
                                                <v-spacer></v-spacer>
                                                <v-btn text color="red" 
                                                    @click="objTrackingSheet._data['trackingstates'][prop].tst_date = null; $refs['date' + prop][0].save(objTrackingSheet._data['trackingstates'][prop].tst_date)">Supprimer</v-btn>
                                            </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs4 v-else>
                                            <v-btn outlined small fab :color="(objTrackingSheet._data['trackingstates'][prop].tst_state_numeric == noticeHeaders[nindex].active) ? 'teal' : 'grey'" 
                                                @click="changeState(prop, noticeHeaders[nindex].active)">
                                                <v-icon v-show="objTrackingSheet._data['trackingstates'][prop].tst_state_numeric == noticeHeaders[nindex].active">mdi-check</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                        </v-simple-table>
                    </v-flex>
                </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-flex>
<!--        <v-flex xs12 class="pl-2 pr-2 pb-1">
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header disable-icon-rotate>
                <template v-slot:actions>
                    <v-icon color="primary">$vuetify.icons.expand</v-icon>
                </template>
                <template v-slot:default class="blue-grey lighten-4">
                    <div><span class="font-weight-bold">Avancement</span></div>
                </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                <v-layout row wrap>
                    <code class="pa-2 caption font-italic theme--dark primary v-toolbar" style="color:white; width:100%;">Pré-diagnostic</code>
                    <v-flex xs12 class="pa-2" >
                        <v-simple-table :dense="false" :fixed-header="false">
                        <template v-slot:default>
                            <thead>
                                <th v-for="(item, index) in noticeHeaders" :key="index" class="text-left" :style="'width:'+ item.width +'%;'">
                                    {{item.text}}
                                </th>
                            </thead>
                            <tbody>
                                <tr v-for="(prop, pindex) in noticeDiag" :key="pindex">
                                    <td v-for="(item, nindex) in noticeHeaders" :key="nindex" class="checkboxfull">
                                        <v-flex v-if="noticeHeaders[nindex].type == 'text'">
                                            {{ typeState['TST'][prop].typ_name }}
                                        </v-flex>
                                        <v-flex v-else-if="noticeHeaders[nindex].type == 'date'">
                                            <v-menu style="z-index:1500" :disabled="routeState.view"
                                            :ref="'date' + prop" :close-on-content-click="false" v-model="noticeDate[prop]"
                                            :return-value.sync="objTrackingSheet._data['trackingstates'][prop].tst_date"
                                            transition="scale-transition" offset-y min-width="290px" >
                                            <template v-slot:activator="{ on }">
                                            <v-chip small outlined color="green" v-on="on">
                                                <span v-if="objTrackingSheet._data['trackingstates'][prop].tst_date">
                                                    {{ locDateFormat.toLocale(objTrackingSheet._data['trackingstates'][prop].tst_date) }}
                                                </span>
                                                <v-icon v-else color="green">mdi-calendar-edit</v-icon>
                                            </v-chip>
                                            </template>
                                            <v-date-picker 
                                                @input="$refs['date' + prop][0].save(objTrackingSheet._data['trackingstates'][prop].tst_date)"
                                                locale="fr" v-model="objTrackingSheet._data['trackingstates'][prop].tst_date">
                                                <v-spacer></v-spacer>
                                                <v-btn text color="red" 
                                                    @click="objTrackingSheet._data['trackingstates'][prop].tst_date = null; $refs['date' + prop][0].save(objTrackingSheet._data['trackingstates'][prop].tst_date)">Supprimer</v-btn>
                                            </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs4 v-else>
                                            <v-btn outlined small fab :color="(objTrackingSheet._data['trackingstates'][prop].tst_state_numeric == noticeHeaders[nindex].active) ? 'teal' : 'grey'" 
                                                @click="changeState(prop, noticeHeaders[nindex].active)">
                                                <v-icon v-show="objTrackingSheet._data['trackingstates'][prop].tst_state_numeric == noticeHeaders[nindex].active">mdi-check</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                        </v-simple-table>
                    </v-flex>
                </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-flex> -->
    <v-flex xs12 class="pl-2 pr-2 pb-1">
        <v-form ref="siteform1" lazy-validation :disabled="routeState.view">
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header disable-icon-rotate>
                <template v-slot:actions>
                    <!-- <v-chip small outlined color="secondary">
                        <v-icon color="primary">mdi-bookmark-plus-outline</v-icon>
                        {{ locDateFormat.toLocale(objTrackingSheet.trs_created_at) }}
                    </v-chip>
                    <v-chip small outlined color="green">
                        <v-icon color="green">mdi-pencil-outline</v-icon>{{ locDateFormat.toLocale(objTrackingSheet.trs_updated_at) }}
                    </v-chip> -->
                    <v-icon color="primary">$vuetify.icons.expand</v-icon>
                </template>
                <template v-slot:default>
                    <div><span class="font-weight-bold">Evaluation</span></div>
                </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                <v-layout row wrap>
                    <code class="pa-2 font-italic theme--dark primary v-toolbar" style="color:white; color:white; width:100%;">Evaluation du potentiel au titre des mesures compensatoires</code>
                    <v-flex xs6 class="pa-2">
                        <auto-multi :title="'Auteur'" :objTrackingSheet.sync="objTrackingSheet" ctt_typ="TSC10"></auto-multi>
                    </v-flex>
                    <v-flex xs6 class="pa-2">
                        <auto-multi :title="'Source'" :objTrackingSheet.sync="objTrackingSheet" ctt_typ="TSC11"></auto-multi>
                    </v-flex>
                    <v-flex xs6 class="pt-0 pb-0 pa-2">
                        <div class="mt-1">Engagement de l'Etat : </div>
                    </v-flex>
                    <v-flex xs6 class="pt-0 pb-0 pa-2">
                        <v-radio-group v-model="objTrackingSheet._data['trackingstates']['TST30'].tst_state_numeric" row class="mt-0">
                            <v-radio label="Oui" :value="2"></v-radio><v-radio label="Non" :value="1"></v-radio>
                        </v-radio-group>
                    </v-flex>
                    <v-flex v-show="objTrackingSheet._data['trackingstates']['TST30'].tst_state_numeric == 2" xs12 class="pt-0 pb-0 pa-2">
                        <v-textarea :readonly="routeState.view" v-model="objTrackingSheet._data['trackingstates']['TST30'].tst_note.eval_engagement_rappel" rows="2" label="Si oui rappel de l'engagement" ></v-textarea>
                    </v-flex>
                    <v-flex xs6 class="pt-0 pb-0 pa-2">
                        <div class="mt-1">Zone de prospection prioritaire :</div>
                    </v-flex>
                    <v-flex xs6 class="pt-0 pb-0 pa-2">
                        <v-radio-group v-model="objTrackingSheet._data['trackingstates']['TST31'].tst_state_numeric" row class="mt-0">
                            <v-radio label="Oui" :value="2"></v-radio><v-radio label="Non" :value="1"></v-radio>
                        </v-radio-group>
                    </v-flex>
                    <v-flex v-show="objTrackingSheet._data['trackingstates']['TST31'].tst_state_numeric == 2" xs12 class="pt-0 pb-0 pa-2">
                        <v-text-field v-model="objTrackingSheet._data['trackingstates']['TST31'].tst_note.eval_nom_zone_prioritaire" label="Nom de la zone"></v-text-field>
                    </v-flex>
                    <v-flex xs6 class="pt-0 pb-0 pa-2">
                        <div class="mt-1">Maitrise foncière prioritaire :</div>
                    </v-flex>
                    <v-flex xs6 class="pt-0 pb-0 pa-2">
                        <v-radio-group v-model="objTrackingSheet._data['trackingstates']['TST31'].tst_note.eval_mait_fonc_prioritaire" row class="mt-0">
                            <v-radio label="Oui" value="CONVENTIONNEMENT"></v-radio><v-radio label="Non" value="ACQUISITION"></v-radio>
                        </v-radio-group>
                    </v-flex>
                    <!-- Descriptif du site et enjeux -->
                    <code class="pa-2 caption font-italic theme--dark primary v-toolbar" style="color:white; color:white; width:100%;">Descriptif du site et enjeux</code>
                    <v-flex xs12 class="pa-2">
                        <v-textarea :readonly="routeState.view" rows="2" solo name="input-7-4" label="Descriptif" v-model="objTrackingSheet._data['trackingstates']['TST32'].tst_note.descriptif_enjeux"></v-textarea>
                    </v-flex>
                    <code class="pa-2 caption font-italic theme--dark primary v-toolbar" style="color:white; width:100%;">Inventaires et zonages réglementaires patrimoine naturel</code>
                    <v-flex xs12 class="pt-2 pl-3 pr-3" >
                        <v-btn v-if="isAuthorized( 'TrackingSheetEcology', 'store' )" outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="switchModal('trackingzone', 0, 1)" :disabled="!routeState.edit">
                            Ajouter<v-icon>mdi-playlist-edit</v-icon>
                        </v-btn>
                    </v-flex>
                    <v-flex xs12 class="pa-2">
                    <v-data-table v-if="loading.getSheetData" :headers="zoneHeaders" :items="objTrackingSheet._data['trackingzone']" :items-per-page="5" class="elevation-1 tab-zindex" :custom-filter="searchFilter">
                        <template v-slot:[`item.tzo_typ_id`]="{ item }">
                            <v-chip label outlined :color="typeState['ZOL'][item.tzo_typ_id].typ_color">
                                {{ typeState['ZOL'][item.tzo_typ_id].typ_name }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.tzo_id`]="{ item }">
                            <v-icon small @click="switchModal('trackingzone', item.tzo_id, 1)" class="mr-2" color="grey">mdi-information-outline</v-icon>
                        </template>
                    </v-data-table>
                    </v-flex>
                    <code class="pa-2 caption font-italic theme--dark primary v-toolbar" style="color:white; width:100%;">Réalisation des diagnostics détaillés</code>
                    <v-flex xs12 class="pt-2 pr-3" styl="text-align: right;">
                        <v-btn v-if="isAuthorized( 'TrackingSheetEcology', 'store' )" outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="switchModal('diagnostic', 0, 1)" :disabled="!routeState.edit">
                            Ajouter<v-icon>mdi-playlist-edit</v-icon>
                        </v-btn>
                    </v-flex>
                    <v-flex xs12 class="pa-2">
                    <v-data-table v-if="loading.getSheetData" :headers="diagHeaders" :items="objTrackingSheet._data['diagnostic']" :items-per-page="5" class="elevation-1 tab-zindex" :custom-filter="searchFilter">
                        <template v-slot:[`item.dia_typ_id`]="{ item }">
                            <v-chip label outlined :color="typeState['DIA'][item.dia_typ_id].typ_color">
                                {{ typeState['DIA'][item.dia_typ_id].typ_name }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.dia_approved`]="{ item }">
                            <v-icon v-if="item.dia_approved">mdi-check</v-icon><v-icon v-else>mdi-alert-remove</v-icon>
                        </template>
                        <template v-slot:[`item.dia_approval_date`]="{ item }">{{ locDateFormat.toLocale( item.dia_approval_date ) }}</template>
                        <template v-slot:[`item.dia_id`]="{ item }">
                            <v-icon small @click="switchModal('diagnostic', item.dia_id, 1)" class="mr-2" color="grey">mdi-information-outline</v-icon>
                        </template>
                    </v-data-table>
                    </v-flex>
                </v-layout>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        </v-form>
    </v-flex>
    <v-flex xs12 class="pl-2 pr-2 pb-1">
        <v-expansion-panels>
            <v-expansion-panel disable-icon-rotate>
                <v-expansion-panel-header>
                <template v-slot:actions>
                    <!-- <v-chip small outlined color="secondary">
                        <v-icon color="primary">mdi-bookmark-plus-outline</v-icon>
                        {{ locDateFormat.toLocale(objTrackingSheet.trs_created_at) }}
                    </v-chip>
                    <v-chip small outlined color="green">
                        <v-icon color="green">mdi-pencil-outline</v-icon>{{ locDateFormat.toLocale(objTrackingSheet.trs_updated_at) }}
                    </v-chip> -->
                    <v-icon color="primary">$vuetify.icons.expand</v-icon>
                </template>
                <template v-slot:default>
                    <div><span class="font-weight-bold">Espèces protégées</span></div>
                </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                <v-layout row wrap>
                    <code class="pa-2 caption font-italic theme--dark primary v-toolbar" style="color:white; width:100%;">Espèces</code>
                    <v-flex xs12 class="pa-2 pr-1 pl-1">
                    <v-data-table v-if="loading.getSheetData" :headers="speciesHeaders" :items="objTrackingSheet._data['species']" 
                        class="elevation-1 tab-zindex" :search="searchSpecies" :custom-filter="searchFilter">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>
                                    <v-alert class="mb-0" border="left" dense colored-border type="info">Espèces végétales / animales</v-alert>
                                </v-toolbar-title>
                                <v-btn v-if="enableAdd" class="ml-2"
                                    :outlined="!(filter.csp_deleted_at)" 
                                    rounded x-small :color="(filter.csp_deleted_at.csp_deleted_at) ? 'green' : 'brown'" @click="filter.csp_deleted_at = !filter.csp_deleted_at; getCompensationspecies()">
                                    Archivés<v-icon>mdi-archive-arrow-down-outline</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-text-field append-icon="mdi-magnify" label="Recherche" v-model="searchSpecies"
                                    clearable single-line hide-details class="ma-4 pa-0" :disabled="false">
                                </v-text-field>
                                <v-btn v-if="isAuthorized( 'TrackingSheetEcology', 'store' )" outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="switchModal('compensationspecies', 0, 1)" :disabled="!routeState.edit">
                                    Ajouter<v-icon>mdi-playlist-edit</v-icon>
                                </v-btn>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.csp_typ_id`]="{ item }">
                            <v-chip label outlined :title="typeState['SEC'][item.csp_typ_id].typ_name"
                                :color="typeState['SEC'][item.csp_typ_id].typ_color" class="text-uppercase">
                                {{ firstLetters(typeState['SEC'][item.csp_typ_id].typ_name) }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.spe_name`]="{ item }">
                            <div :class="(item.csp_deleted_at) ? 'red--text' : ''">
                            <span v-if="item.spe_name && !item.csp_value_valid" :class="(!item.csp_value_valid) ? 'font-italic' : ''" :title="item.spe_latin_name">{{ item.spe_name }}</span>
                            <span v-else-if="item.spe_name" :class="(item.csp_value_valid && spe_typ_id_debt !== 'DEB02' ) ? 'font-weight-bold' : ''" :title="item.spe_latin_name">{{ item.spe_name }}</span>
                            <span v-else :class="(item.csp_value_valid) ? 'font-weight-bold' : ''">{{ item.csp_note }}</span>
                            </div>
                        </template>
                        <template v-slot:[`item.csp_note`]="{ item }"> <!-- Todooo -->
                            <v-chip label outlined :color="stateState['GES'][item.csp_sta_id].sta_color" 
                                    :title="stateState['GES'][item.csp_sta_id].sta_description">
                                {{ stateState['GES'][item.csp_sta_id].sta_label }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.csp_value_sub`]="{ item }">
                            <div :class="(item.csp_deleted_at) ? 'red--text' : ''">
                                {{ (Math.round(item.csp_value_sub * 100) / 100).toFixed(2) }}
                            </div>
                        </template>
                        <template v-slot:[`item.csp_value_valid`]="{ item }">
                            <div :class="(item.csp_deleted_at) ? 'red--text' : ''">{{ (!item.csp_linear_state) ? (Math.round(item.csp_value_valid * 100) / 100).toFixed(2) : '-' }}</div>
                        </template>
                        <template v-slot:[`item.csp_linear_state`]="{ item }">
                            <div :class="(item.csp_deleted_at) ? 'red--text' : ''">{{ (item.csp_linear_state) ? Math.round((item.csp_value_valid * 100) / 100).toFixed(2) : '-' }}</div>
                        </template>
                        <template v-slot:[`item.csp_id`]="{ item }">
                            <v-icon small @click="switchModal('compensationspecies', item.csp_id, 1)" class="mr-2" color="grey">mdi-information-outline</v-icon>
                            <v-icon v-if="item.csp_deleted_at && isAuthorized( 'TrackingSheetEcology', 'store' )" small @click="undeleteCompensation(item)" class="mr-2" color="grey" title="Restaurer la fiche">mdi-delete-off-outline</v-icon>
                        </template>
                    </v-data-table>
                    </v-flex>
                </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-flex>
    <v-flex xs12 class="pl-2 pr-2 pb-1">
        <v-expansion-panels>
            <v-expansion-panel disable-icon-rotate>
                <v-expansion-panel-header>
                <template v-slot:actions>
                    <!--  <v-chip small outlined color="secondary">
                        <v-icon color="primary">mdi-bookmark-plus-outline</v-icon>
                        {{ locDateFormat.toLocale(objTrackingSheet.trs_created_at) }}
                    </v-chip>
                    <v-chip small outlined color="green">
                        <v-icon color="green">mdi-pencil-outline</v-icon>{{ locDateFormat.toLocale(objTrackingSheet.trs_updated_at) }}
                    </v-chip> -->
                    <v-icon color="primary">$vuetify.icons.expand</v-icon>
                </template>
                <template v-slot:default>
                    <div><span class="font-weight-bold">Compensation loi sur l'eau</span></div>
                </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                <v-layout row wrap>
                    <code class="pa-2 caption font-italic theme--dark primary v-toolbar" style="color:white; width:100%;">Compensation dues au titre de la Loi sur l'eau</code>
                    <v-flex xs12 class="pt-2 pl-1 pr-1" >
                    <v-data-table v-if="loading.getSheetData" :headers="waterHeaders" :items="objTrackingSheet._data['water']" :items-per-page="5" class="elevation-2 tab-zindex" :custom-filter="searchFilter">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>
                                    <v-alert class="mb-0" border="left" dense colored-border type="info">Compensation dues au titre de la Loi sur l'eau</v-alert>
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                            <v-btn v-if="isAuthorized( 'TrackingSheetEcology', 'store' )" outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="switchModal('compensationwater', 0, 1)" :disabled="!routeState.edit">
                                Ajouter<v-icon>mdi-playlist-edit</v-icon>
                            </v-btn>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.cwa_typ_id`]="{ item }">
                        <v-chip label outlined :color="typeState['CMP'][item.cwa_typ_id].typ_color">{{ typeState['CMP'][item.cwa_typ_id].typ_name }}</v-chip>
                        </template>
                        <template v-slot:[`item.cwa_linear_state`]="{ item }">
                            <v-chip label outlined>{{ (item.cwa_linear_state) ? 'Surface' : 'Linéaire' }}</v-chip>
                        </template>
                        <template v-slot:[`item.cwa_value_det_diag`]="{ item }" >{{ (Math.round(item.cwa_value_det_diag * 100) / 100 ).toFixed(2) }}</template>
                        <template v-slot:[`item.cwa_value_validated`]="{ item }">{{ (Math.round(item.cwa_value_validated * 100) / 100).toFixed(2) }}</template>
                        <template v-slot:[`item.cwa_id`]="{ item }">
                            <v-icon small @click="switchModal('compensationwater', item.cwa_id, 1)" class="mr-2" color="grey">mdi-information-outline</v-icon>
                        </template>
                    </v-data-table>
                    </v-flex>

                    <v-flex xs12 class="pt-2 pl-1 pr-1" >
                    <v-data-table v-if="loading.getSheetData" :headers="initImpactHeaders" :items="objTrackingSheet._data['initimpact']" :items-per-page="5" class="elevation-2 tab-zindex" :custom-filter="searchFilter">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>
                                <v-alert class="mb-0" border="left" dense colored-border type="info">Caractéristiques du site d'impact initial</v-alert>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn v-if="isAuthorized( 'TrackingSheetEcology', 'store' )" outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="switchModal('initialimpact', 0, 1)" :disabled="!routeState.edit">
                                Ajouter<v-icon>mdi-playlist-edit</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </template>
                        <template v-slot:[`item.ini_typ_id`]="{ item }">
                        <v-chip label outlined :color="typeState['CMP'][item.ini_typ_id].typ_color">{{ typeState['CMP'][item.ini_typ_id].typ_name }}</v-chip>
                        </template>
                        <template v-slot:[`item.ini_id`]="{ item }">
                            <v-icon small @click="switchModal('initialimpact', item.ini_id, 1)" class="mr-2" color="grey">mdi-information-outline</v-icon>
                        </template>
                    </v-data-table>
                    </v-flex>

                    <v-flex xs12 class="pt-2 pl-1 pr-1" >
                    <v-data-table v-if="loading.getSheetData" :headers="habitatHeaders" :items="objTrackingSheet._data['habitat']" :items-per-page="5" class="elevation-2 tab-zindex" :custom-filter="searchFilter">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>
                                    <v-alert class="mb-0" border="left" dense colored-border type="info">Caractéristiques du site de compensation</v-alert>
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn v-if="isAuthorized( 'TrackingSheetEcology', 'store' )" outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="switchModal('compensationhabitat', 0, 1)" :disabled="!routeState.edit">
                                    Ajouter<v-icon>mdi-playlist-edit</v-icon>
                                </v-btn>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.cha_value_det_diag`]="{ item }" >{{ (Math.round(item.cha_value_det_diag  * 100) / 100).toFixed(2) }}</template>
                        <template v-slot:[`item.cha_value_validated`]="{ item }">{{ (Math.round(item.cha_value_validated * 100) / 100).toFixed(2) }}</template>
                        <template v-slot:[`item.cha_id`]="{ item }">
                            <v-icon small @click="switchModal('compensationhabitat', item.cha_id, 1)" class="mr-2" color="grey">mdi-information-outline</v-icon>
                        </template>
                    </v-data-table>
                    </v-flex>

                    <v-flex xs12 class="pt-2 pl-1 pr-1" >
                    <v-form ref="siteform3" lazy-validation :disabled="routeState.view">
                    <v-data-table v-if="loading.getSheetData" :headers="habitatDetHeaders" :items="objTrackingSheet._data['habitatdet']" :items-per-page="5" class="elevation-2 tab-zindex" :custom-filter="searchFilter">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>
                                    <v-alert class="mb-0" border="left" dense colored-border type="info">Caractéristiques détaillées du site de compensation</v-alert>
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <!-- <v-btn v-if="isAuthorized( 'trackingsheetedit', 'update' )" outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="switchModal('compensationhabitatdet', 0, 1)" :disabled="!routeState.edit">
                                    Ajouter<v-icon>mdi-playlist-edit</v-icon>
                                </v-btn> -->
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.chd_sta_id`]="{ item }">
                            <v-chip label outlined :color="(item.chd_id) ? stateState['CHD'][item.chd_sta_id].sta_color : 'grey'">
                                {{ stateState['CHD'][item.chd_sta_id].sta_label }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.chd_bio_state`]="{ item }">
                            <v-checkbox dense class="ma-0 pa-0" v-model="item.chd_bio_state"   label="Biologie"></v-checkbox>
                            <v-checkbox dense class="ma-0 pa-0" v-model="item.chd_hydro_state" label="Hydrologique"></v-checkbox>
                            <v-checkbox dense class="ma-0 pa-0" v-model="item.chd_epur_state"  label="Epuratoire"></v-checkbox>
                        </template>
                        <template v-slot:[`item.chd_id`]="{ item }">
                            <v-icon small @click="itemStaId=item.chd_sta_id; switchModal('compensationhabitatdet', item.chd_id, 1)" class="mr-2" color="grey">mdi-information-outline</v-icon>
                        </template>
                    </v-data-table>
                    </v-form>
                    </v-flex>
                </v-layout>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-flex>
    <v-flex xs12 class="pl-2 pr-2 pb-1">
        <v-expansion-panels>
            <v-expansion-panel >
                <v-expansion-panel-header disable-icon-rotate>
                <template v-slot:actions>
                    <!--  <v-chip small outlined color="secondary">
                        <v-icon color="primary">mdi-bookmark-plus-outline</v-icon>
                        {{ locDateFormat.toLocale(objTrackingSheet.trs_created_at) }}
                    </v-chip>
                    <v-chip small outlined color="green">
                        <v-icon color="green">mdi-pencil-outline</v-icon>{{ locDateFormat.toLocale(objTrackingSheet.trs_updated_at) }}
                    </v-chip> -->
                    <v-icon color="primary">$vuetify.icons.expand</v-icon>
                </template>
                <template v-slot:default>
                    <div><span class="font-weight-bold">Plan de restauration et de gestion</span></div>
                </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                <v-layout row wrap>
                    <code class="pa-2 caption font-italic theme--dark primary v-toolbar" style="color:white; width:100%;">Plan de restauration</code>
                    <v-flex xs4 class="pl-1">
                        <v-checkbox dense v-model="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_approuve" label="Approuvé par LISEA / COSEA" :disabled="routeState.view"></v-checkbox>
                    </v-flex>
                    <v-flex xs2 class="pl-1 pa-1">
                        <v-menu style="z-index:1500"
                        ref="date_planrest" :close-on-content-click="false" v-model="fromDate['TST38']"
                        :return-value.sync="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_date_approbation"
                        transition="scale-transition" offset-y min-width="290px" >
                        <template v-slot:activator="{ on }">
                        <v-chip small outlined color="green" v-on="on" v-show="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_approuve">
                            <span v-if="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_date_approbation">
                                {{ locDateFormat.toLocale(objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_date_approbation) }}
                            </span>
                            <v-icon v-else color="grey">mdi-calendar-edit</v-icon>
                        </v-chip>
                        </template>
                        <v-date-picker :disabled="routeState.view"
                            @input="$refs['date_planrest'].save(objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_date_approbation)"
                            locale="fr" v-model="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_date_approbation">
                            <v-spacer></v-spacer>
                            <v-btn text color="red" :disabled="routeState.view"
                                @click="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_date_approbation = null; $refs['date_planrest'].save(objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_date_approbation)">Supprimer</v-btn>
                        </v-date-picker>
                        </v-menu>
                    </v-flex>
                    <v-flex xs4 class="pl-1">
                        <v-checkbox dense v-model="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_travaux_en_cours" label="Travaux en cours" :disabled="routeState.view"></v-checkbox>
                    </v-flex>
                    <v-flex xs2 class="pl-1"></v-flex>

                    <v-flex xs4 class="pl-1">
                        <v-checkbox dense v-model="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_solde" label="Soldé (travaux validés par la DREAL)" :disabled="routeState.view"></v-checkbox>
                    </v-flex>
                    <v-flex xs2 class="pl-1"></v-flex>
                    <v-flex xs4 class="pl-1">
                        <v-checkbox dense v-model="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_travaux_termines" label="Travaux terminés" :disabled="routeState.view"></v-checkbox>
                    </v-flex>
                    <v-flex xs2 class="pl-1 pa-1">
                        <v-menu style="z-index:1500"
                        ref="date_planended" :close-on-content-click="false" v-model="fromDate['TST38_1']"
                        :return-value.sync="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_date_ended"
                        transition="scale-transition" offset-y min-width="290px" >
                        <template v-slot:activator="{ on }">
                        <v-chip small outlined color="green" v-on="on" v-show="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_travaux_termines">
                            <span v-if="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_date_ended">
                                {{ locDateFormat.toLocale(objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_date_ended) }}
                            </span>
                            <v-icon v-else color="grey">mdi-calendar-edit</v-icon>
                        </v-chip>
                        </template>
                        <v-date-picker :disabled="routeState.view"
                            @input="$refs['date_planended'].save(objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_date_ended)"
                            locale="fr" v-model="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_date_ended">
                            <v-spacer></v-spacer>
                            <v-btn text color="red" :disabled="routeState.view"
                                @click="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_date_ended = null; 
                                $refs['date_planended'].save(objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_date_ended)">Supprimer</v-btn>
                        </v-date-picker>
                        </v-menu>
                    </v-flex>

                    <v-flex xs4 class="pl-1"></v-flex><v-flex xs2 class="pl-1"></v-flex>
                    <v-flex xs4 class="pl-1">
                        <v-checkbox dense v-model="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_travaux_recep" label="Travaux réceptionnés" :disabled="routeState.view"></v-checkbox>
                    </v-flex>
                    <v-flex xs2 class="pl-1 pa-1">
                        <v-menu style="z-index:1500"
                        ref="date_travaux_recep" :close-on-content-click="false" v-model="fromDate['TST38_2']"
                        :return-value.sync="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_travaux_recep_date"
                        transition="scale-transition" offset-y min-width="290px" >
                        <template v-slot:activator="{ on }">
                        <v-chip small outlined color="green" v-on="on" v-show="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_travaux_recep">
                            <span v-if="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_travaux_recep_date">
                                {{ locDateFormat.toLocale(objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_travaux_recep_date) }}
                            </span>
                            <v-icon v-else color="grey">mdi-calendar-edit</v-icon>
                        </v-chip>
                        </template>
                        <v-date-picker :disabled="routeState.view"
                            @input="$refs['date_travaux_recep'].save(objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_travaux_recep_date)"
                            locale="fr" v-model="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_travaux_recep_date">
                            <v-spacer></v-spacer>
                            <v-btn text color="red" :disabled="routeState.view"
                                @click="objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_travaux_recep_date = null; 
                                $refs['date_travaux_recep'].save(objTrackingSheet._data['trackingstates']['TST38'].tst_note.plan_rest_travaux_recep_date)">Supprimer</v-btn>
                        </v-date-picker>
                        </v-menu>
                    </v-flex>
                    <!-- Tableau des actions  -->
                    <v-flex xs12 class="pa-2 pr-1 pl-1">
                    <event-tab  :eve_xxx_id="objTrackingSheet.trs_id" :table_id="'trs'" :routeState="routeState" :typfilter="'WRK02'" :title="'Travaux réalisés :'"></event-tab>
                    </v-flex>
                    <code class="pa-2 caption font-italic theme--dark primary v-toolbar" style="color:white; width:100%;">Plan de gestion</code>
                    <v-flex xs4 class="pt-0 pb-0 pa-2">
                        <v-checkbox dense v-model="objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_approuve" label="Approuvé par LISEA / COSEA" :disabled="routeState.view"></v-checkbox>
                    </v-flex>
                    <v-flex xs3 class="mt-4 pt-0 pb-0 pa-2">
                        <v-menu style="z-index:1500"
                        ref="date_plangest" :close-on-content-click="false" v-model="fromDate['TST39']"
                        :return-value.sync="objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_date_approbation"
                        transition="scale-transition" offset-y min-width="290px" >
                        <template v-slot:activator="{ on }">
                        <v-chip small outlined color="green" v-on="on" v-show="objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_approuve">
                            <span v-if="objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_date_approbation">
                                {{ locDateFormat.toLocale(objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_date_approbation) }}
                            </span>
                            <v-icon v-else color="grey">mdi-calendar-edit</v-icon>
                        </v-chip>
                        </template>
                        <v-date-picker :disabled="routeState.view"
                            @input="$refs['date_plangest'].save(objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_date_approbation)"
                            locale="fr" v-model="objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_date_approbation">
                            <v-spacer></v-spacer>
                            <v-btn text color="red" :disabled="routeState.view"
                                @click="objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_date_approbation = null; $refs['date_plangest'].save(objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_date_approbation)">Supprimer</v-btn>
                        </v-date-picker>
                        </v-menu>
                    </v-flex>
                    <v-flex xs5 class="pt-0 pb-0 pa-2">
                        <v-checkbox :disabled="routeState.view" dense v-model="objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_travaux_en_cours" label="Actions en cours" ></v-checkbox>
                    </v-flex>
                    <v-flex xs4 class="pt-0 pb-0 pa-2">
                        <v-checkbox :disabled="routeState.view" dense v-model="objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_solde" label="Soldé (travaux validés par la DREAL)" ></v-checkbox>
                    </v-flex>
                    <v-flex xs3 class="mt-4 pt-0 pb-0 pa-2">
                        <!-- action soldée -->
                        <v-menu style="z-index:1500" 
                        ref="date_plangest2" :close-on-content-click="false" v-model="fromDate['TST391']"
                        :return-value.sync="objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_date_solde"
                        transition="scale-transition" offset-y min-width="290px" >
                        <template v-slot:activator="{ on }">
                        <v-chip small outlined color="green" v-on="on" v-show="objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_solde">
                            <span v-if="objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_date_solde">
                                {{ locDateFormat.toLocale(objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_date_solde) }}
                            </span>
                            <v-icon v-else color="grey">mdi-calendar-edit</v-icon>
                        </v-chip>
                        </template>
                        <v-date-picker :disabled="routeState.view"
                            @input="$refs['date_plangest2'].save(objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_date_solde)"
                            locale="fr" v-model="objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_date_solde">
                            <v-spacer></v-spacer>
                            <v-btn text color="red" :disabled="routeState.view"
                                @click="objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_date_solde = null; $refs['date_planrest'].save(objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_date_solde)">Supprimer</v-btn>
                        </v-date-picker>
                        </v-menu>                            
                    </v-flex>
                    <v-flex xs5 class="pt-0 pb-0 pa-2">
                        <v-checkbox :disabled="routeState.view" dense v-model="objTrackingSheet._data['trackingstates']['TST39'].tst_note.plan_gest_travaux_termines" label="Actions terminées" ></v-checkbox>
                    </v-flex>
                    <v-flex xs12 class="pa-2 pr-0 pl-0">
                        <event-tab  :eve_xxx_id="objTrackingSheet.trs_id" :table_id="'trs'" :routeState="routeState" :typfilter="'WRK01'" :title="'Action réalisées :'"></event-tab>
                    </v-flex>
                </v-layout>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-flex>
    </v-layout>
    <!-- Edition tableau -->
    <v-dialog v-model="modalEdit" max-width="900">
        <edit-zone   v-if="(modalName == 'trackingzone')" :switchModal="switchModal" :objTrackingSheet="objTrackingSheet" :itemId="itemId" ></edit-zone>
        <edit-diagnostic v-if="(modalName == 'diagnostic')" :switchModal="switchModal" :objTrackingSheet="objTrackingSheet" :itemId="itemId" ></edit-diagnostic>
        <edit-csp    v-if="(modalName == 'compensationspecies')" :switchModal="switchModal" :objTrackingSheet="objTrackingSheet" :itemId="itemId" ></edit-csp>
        <edit-water  v-if="(modalName == 'compensationwater')" :switchModal="switchModal" :objTrackingSheet="objTrackingSheet" :itemId="itemId" ></edit-water>
        <edit-impact v-if="(modalName == 'initialimpact')" :switchModal="switchModal" :objTrackingSheet="objTrackingSheet" :itemId="itemId" ></edit-impact>
        <edit-cha    v-if="(modalName == 'compensationhabitat')" :switchModal="switchModal" :objTrackingSheet="objTrackingSheet" :itemId="itemId" ></edit-cha>
        <edit-chd    v-if="(modalName == 'compensationhabitatdet')" :switchModal="switchModal" :objTrackingSheet="objTrackingSheet" :itemId="itemId" :itemStaId="itemStaId" ></edit-chd>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale       from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import autoCompMulti    from './Autocomplete_contact.vue'
import editZone         from '../components/Edit_zone.vue'
import editDiagnostic   from '../components/Edit_diagnostic.vue'
import editCsp          from '../components/Edit_compensation_spe.vue'
import editWater        from '../components/Edit_compensation_water.vue'
import editImpact       from '../components/Edit_initial_impact.vue'
import editCha          from '../components/Edit_compensation_habitat.vue'
import editChd          from '../components/Edit_compensation_habitat_det.vue'
import eventTab         from '../components/Tab_event.vue'

import { usrGetters }   from "../store/user.module"
import { toolHelper }  from '../mixins/tool_helper.js'

export default {
    name: 'site_edit',
    props: ['objTrackingSheet', 'routeState'], 
    mixins: [ toolHelper ],
    data: function () {
        return {
            enableAdd: true,
            modalEdit: false,
            modalName: false,
            itemId: 0,
            itemStaId: '',
            loading: { getVersion: false, getContact: false, getSheetData: false },
            validFrom: true,
            saveProgress: false,         // Enregistrement en cours...
            locDateFormat: dateLocale, // Format de date
            fromDate: { },
            noticeHeaders: [
                { text: ' ',        type:'text',  width: '34' },
                { text: 'En cours', type:'value', width: '22', active: 1 },
                { text: 'Validé',   type:'value', width: '22', active: 2 },
                { text: 'Rejeté',   type:'value', width: '22', active: 3 },
                { text: '',         type:'date',  width: '8',  active: 2 },
            ],
            noticeProp: cacheGetters.getTypesByTag('TST', 'PRO'), //noticeDiag: cacheGetters.getTypesByTag('TST', 'ECA'),
            noticeDate: {},
            typeState: cacheGetters.getTypes(),
            stateState: cacheGetters.getStates(),
            compDetState: cacheGetters.getStates('CHD'),
            zoneHeaders: [
                { text: 'Libelle', value:'tzo_typ_id' },
                { text: 'Nom',     value:'tzo_name' },
                { text: ' ',       value:'tzo_id', sortable: false },
            ],
            diagHeaders: [
                { text: 'Type',     value:'dia_typ_id' },
                { text: 'Organisme',value:'dia_ctt_mandat_name' },
                { text: 'Auteur',   value:'dia_ctt_press_name' },
                { text: 'Approuvé', value:'dia_approved' },
                { text: 'Date',     value:'dia_approval_date' },
                //{ text: 'Commentaire', value:'dia_observation' },
                { text: ' ',        value:'dia_id', sortable: false },
            ],
            searchSpecies: '',
            speciesHeaders: [
                { text: 'Type',        value:'csp_typ_id' },
                { text: 'Espèces',     value:'spe_name' },
                { text: 'Groupe',     value:'spg_name' },
                { text: 'Echelle geo', value:'csp_note' },
                { text: 'Demandé',     value:'csp_value_sub' },
                { text: 'Validé ha',   value:'csp_value_valid' },
                { text: 'Validé ml',   value:'csp_linear_state' },
                { text: 'Détails',     value:'csp_id', sortable: false },
            ],
            waterHeaders: [
                { text: 'Type compensation',    value:'cwa_typ_id' },
                { text: 'Bassin versant',       value:'was_name' },
                { text: 'Masse d\'eau',         value:'wam_name' },
                { text: 'Unité',                value:'cwa_linear_state' },
                { text: 'Compensation proposée', value:'cwa_value_det_diag' },
                { text: 'Compensation validée', value:'cwa_value_validated' },
                { text: ' ',                 value:'cwa_id', sortable: false },
            ],
            initImpactHeaders: [
                { text: 'Enjeux écologiques',             value:'ini_typ_id' },
                { text: 'Habitat ZH / Habitat simplifié', value:'hab_name' },
                { text: ' ',                              value:'ini_id', sortable: false },
            ],
            habitatHeaders: [
                { text: 'Habitat ZH',                   value:'hab_name' },
                { text: 'Estim. pré-diag',              value:'cha_value_pre_diag' },
                { text: 'Linéaire soumis à validation', value:'cha_value_det_diag' },
                { text: 'Linéaire de compensation validé', value:'cha_value_validated' },
                { text: ' ',                              value:'cha_id', sortable: false },
            ],
            habitatDetHeaders: [
                { text: 'Enjeux eco.',        value:'chd_sta_id' },
                { text: 'Plus value sur ZH',  value:'chd_bio_state' },
                { text: 'Surface',            value:'chd_area' },
                { text: 'Habitat ZH',         value:'chd_id' },
            ],
            filter: {
                typ_id: [],
                csp_deleted_at: false
            },
            isAuthorized: usrGetters.getScopeRight,
        }
    },
    mounted:function (){
        this.loadTabData()
    },
    methods: {
        async loadTabData(){
            await this.getTrackingzone()
            await this.getDiagnostics()
            await this.getCompensationspecies()
            await this.getCompensationwater()
            await this.getInitialimpact()
            await this.getCompensationhabitat()
            await this.getCompensationhabitatDet()
            this.loading.getSheetData = true
        },
        changeState(prop, state){
            if( this.routeState.edit ){
                this.objTrackingSheet._data['trackingstates'][prop].tst_state_numeric = state
                this.$emit('update:objTrackingSheet', this.objTrackingSheet)
            }
        },
        getTrackingzone(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/trackingzone/?per_page=false&tzo_trs_id=' + this.objTrackingSheet.trs_id ).then( (response) => {
                    this.$set(this.objTrackingSheet._data, 'trackingzone', response.data.data)
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })

        },
        getDiagnostics(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/diagnostics/?extended=true&per_page=false&dia_trs_id=' + this.objTrackingSheet.trs_id ).then( (response) => {
                    this.$set(this.objTrackingSheet._data, 'diagnostic', response.data.data)
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getCompensationspecies(){
            return new Promise( (resolve, reject) => {
                let add = (this.filter.csp_deleted_at) ? '&deleted=true' : ''
                this.$http.get( '/compensationspecies/?extended=true&per_page=false&csp_trs_id=' + this.objTrackingSheet.trs_id + add ).then( (response) => {
                    this.$set(this.objTrackingSheet._data, 'species', response.data.data)
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getCompensationwater(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/compensationwater/?extended=true&per_page=false&cwa_trs_id=' + this.objTrackingSheet.trs_id ).then( (response) => {
                    this.$set(this.objTrackingSheet._data, 'water', response.data.data)
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getInitialimpact(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/initialimpact/?extended=true&per_page=false&ini_trs_id=' + this.objTrackingSheet.trs_id ).then( (response) => {
                    this.$set(this.objTrackingSheet._data, 'initimpact', response.data.data)
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getCompensationhabitat(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/compensationhabitat/?extended=true&per_page=false&cha_trs_id=' + this.objTrackingSheet.trs_id ).then( (response) => {
                    this.$set(this.objTrackingSheet._data, 'habitat', response.data.data)
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getCompensationhabitatDet(){
                let items = []
                this.compDetState.forEach(state => {
                    items.push( { 
                        chd_id: 0, chd_trs_id: this.objTrackingSheet.trs_id, chd_sta_id: state.sta_id, chd_bio_state: 0,
                        chd_hydro_state: 0, chd_epur_state: 0, chd_area: 0, chd_order: 1,
                    })
                })
            this.$http.get( '/compensationhabitatdet/?extended=true&per_page=false&chd_trs_id=' + this.objTrackingSheet.trs_id ).then( (response) => {
                items.forEach( (item, index) => {
                    response.data.data.forEach( itemReq => {
                        if( itemReq.chd_sta_id == item.chd_sta_id ){
                            items[index] = itemReq
                        }
                    })
                })
                this.$set(this.objTrackingSheet._data, 'habitatdet', items)
            })            
        },
        undeleteCompensation(item){
            this.$root.$confirm('Restaurer la fiche', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm && item.csp_id > 0 ){
                    this.$http.put( '/compensationspecies/' + item.csp_id + '/restore', { csp_sta_id_state: 'GEN01' } ).then( () =>{
                        this.getCompensationspecies()
                        this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Restauration effecuée.' })
                    }).catch( (error) => { 
                        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Restauration en erreur ' + error })
                    })
                }
            })
        },
        switchModal(name, itemId, mode, refresh){
            name    = name    || this.modalName
            mode    = mode    || 0
            refresh = refresh || false
            this.itemId    = itemId || 0
            this.modalEdit = mode
            this.modalName = name
            if( refresh ){ 
                switch (this.modalName) {
                    case 'trackingzone':
                        this.getTrackingzone()
                        break;
                    case 'diagnostic':
                        this.getDiagnostics()
                        break;
                    case 'compensationspecies':
                        this.getCompensationspecies()
                        break;
                    case 'compensationwater':
                        this.getCompensationwater()
                        break;
                    case 'initialimpact':
                        this.getInitialimpact()
                        break;
                    case 'compensationhabitat':
                        this.getCompensationhabitat()
                        break;
                    case 'compensationhabitatdet':
                        this.getCompensationhabitatDet()
                        break;
                }
            }
            this.modalName = (mode) ? this.modalName : ''
        },
        firstLetters(str, separator){
            separator = separator || ''
            var matches = str.split(' ').map(i => i.charAt(0)) //str.match(/\b(\w)/g);
            var acronym = matches.join(separator)
            return acronym
        }
    },
    components: {
        'auto-multi'     : autoCompMulti,
        'edit-zone'      : editZone,
        'edit-diagnostic': editDiagnostic,
        'edit-csp'       : editCsp,
        'edit-water'     : editWater,
        'edit-impact'    : editImpact,
        'edit-cha'       : editCha,
        'edit-chd'       : editChd,
        'event-tab': eventTab,
    }
}
</script>

<style lang="css">
/* Personnalisation code */
.tab-zindex {
  z-index: 1057;
}
</style>