<template>
<v-card color="grey lighten-4" flat>
    <v-toolbar color="green" dark class="elevation-2" height="50px">
        <v-icon class="mr-3">mdi-chevron-down</v-icon>
        <v-toolbar-title>{{ cardTitle + ' ' + formLabelsByType[objItem.csp_typ_id].fieldName }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="" @click.native="switchModal('compensationspecies', 0, 0)"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text class="mt-2">
        <v-form ref="userForm" v-model="validForm" lazy-validation>
            <v-layout row wrap>
            <v-flex xs6 class="pa-2">
                <v-select 
                    :items="typeItem" v-model="objItem.csp_typ_id" label="Type espèces"
                    item-text="typ_name" item-value="typ_id" :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )">
                </v-select>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-select 
                    :items="stateItem" v-model="objItem.csp_sta_id" label="Type échelle géographique"
                    item-text="sta_label" item-value="sta_id" :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )">
                </v-select>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <auto-multi v-if="!loading.getItem" :title="'Espèce'" ressource="species" :listObject.sync="autoComplete.csp_spe_id"
                    :listParent.sync="autoCompleteSource.spe" :fields="{ index: 'spe_id', text:'spe_name,spe_latin_name', search:'spe_name', multiSelect:false }"
                    class="ml-2 mr-2" :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )">
                </auto-multi>
            </v-flex>
            <v-flex xs6 class="pa-2" v-show="(objItem.csp_sta_id == 'GES01') || (objItem.csp_sta_id == 'GES05')">
                <auto-multi v-if="!loading.getItem" :title="'Petite région agricole'" ressource="smallagrregion" :listObject.sync="autoComplete.csp_sar_id"
                    :listParent.sync="autoCompleteSource.sar" :fields="{ index: 'sar_id', text:'sar_name', search:'sar_name', multiSelect:false, prefetch:true }"
                    class="ml-2 mr-2" :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )">
                </auto-multi>
            </v-flex>
            <v-flex xs6 class="pa-2" v-show="(objItem.csp_sta_id == 'GES03')">
                <auto-multi v-if="!loading.getItem" :title="'Bassin versant'" ressource="watershed" :listObject.sync="autoComplete.csp_was_id"
                    :listParent.sync="autoCompleteSource.was" :fields="{ index: 'was_id', text:'was_name', search:'was_name', multiSelect:false, prefetch:true }"
                    class="ml-2 mr-2">
                </auto-multi>
            </v-flex>
            <v-flex xs6 class="pa-2" v-show="(objItem.csp_sta_id == 'GES02')">
                <auto-multi v-if="!loading.getItem" :title="'Masse d\'eau'" ressource="watermass" :listObject.sync="autoComplete.csp_wam_id"
                    :listParent.sync="autoCompleteSource.wam" 
                    :fields="{ resultlimit:60, params: 'order_by=wam_name&order_direction=asc', index: 'wam_id', text:'was_name,wam_name', search:'wam_name', params:'extended=withwatershead', multiSelect:false, prefetch:true }"
                    class="ml-2 mr-2">
                </auto-multi>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field :label="formLabelsByType[objItem.csp_typ_id].unit + ' estimée favorable'" 
                    v-model="objItem.csp_value_estim" required :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field :label="formLabelsByType[objItem.csp_typ_id].unit + ' soumise à validation'" 
                    v-model="objItem.csp_value_sub" required :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field :label="formLabelsByType[objItem.csp_typ_id].other" 
                    v-model="objItem.csp_note" :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field :label="formLabelsByType[objItem.csp_typ_id].unit + ' compensation validée'" 
                v-model="objItem.csp_value_valid" required :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2" v-if="formLabelsByType[objItem.csp_typ_id].n">
                <v-text-field :label="formLabelsByType[objItem.csp_typ_id].n" 
                    v-model="objItem.csp_value_nest" required :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2" v-if="formLabelsByType[objItem.csp_typ_id].r">
                <v-text-field :label="formLabelsByType[objItem.csp_typ_id].r" 
                    v-model="objItem.csp_value_rep" required :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2" v-if="formLabelsByType[objItem.csp_typ_id].a">
                <v-text-field :label="formLabelsByType[objItem.csp_typ_id].a" 
                    v-model="objItem.csp_value_food" required :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2" v-if="formLabelsByType[objItem.csp_typ_id].h">
                <v-text-field :label="formLabelsByType[objItem.csp_typ_id].h" 
                    v-model="objItem.csp_value_hib" required :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2" v-if="formLabelsByType[objItem.csp_typ_id].d">
                <v-text-field :label="formLabelsByType[objItem.csp_typ_id].d" 
                    v-model="objItem.csp_value_trav" required :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2" v-if="formLabelsByType[objItem.csp_typ_id].rs">
                <v-text-field :label="formLabelsByType[objItem.csp_typ_id].rs" 
                    v-model="objItem.csp_value_resp" required :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                    <v-select :items="advices" :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )"
                        item-text="adv_date" item-value="adv_id"
                        v-model="objItem.csp_adv_id" label="Avis" single-line style="z-index:1500">
                        <template slot="selection" slot-scope="data">
                            {{ getCacheType('ADT', data.item.adv_typ_id_advice).typ_name + ' ' + $date(data.item.adv_date_advice).format('DD/MM/YYYY') }}
                        </template>
                        <template slot="item" slot-scope="data">
                            {{ getCacheType('ADT', data.item.adv_typ_id_advice).typ_name + ' ' + $date(data.item.adv_date_advice).format('DD/MM/YYYY') }}
                        </template>
                    </v-select>
            </v-flex>
            <v-flex xs3 class="pa-2">
                <DatePicker v-if="!loading.getItem && isAuthorized( 'TrackingSheetEcology', 'store' )" @change="dateValidDisabled = false" label="Date de validité" :objectForm="objItem" fieldName='csp_date_valid'></DatePicker>
                <span v-else>Date de validité : <br />{{objItem.csp_date_valid}}</span>
            </v-flex>
            <v-flex xs3 class="pa-2">
                <DatePicker v-if="!loading.getItem && isAuthorized( 'TrackingSheetEcology', 'store' )" @change="dateValidDisabled = false" label="Date fin validité" :objectForm="objItem" fieldName='csp_date_valid_end'></DatePicker>
                <span v-else>Date fin validité : <br />{{objItem.csp_date_valid_end}}</span>
            </v-flex>
            </v-layout>
        </v-form>
    </v-card-text>
    <v-card-actions style="background-color:white;">
        <v-btn color="error" v-if="!loading.getItem && !objItem.csp_deleted_at && itemId > 0 && isAuthorized( 'TrackingSheetEcology', 'store' )" :loading="saveProgress" @click.native="deleteItem()" :disabled="!validForm">
            <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="orange"  v-if="!loading.getItem && isAuthorized( 'TrackingSheetEcology', 'store' ) && itemId > 0" :loading="saveProgress" @click.native="saveItemReplace()" :disabled="( dateValidDisabled || saveProgress || isDeleted )">Nouvelle surface</v-btn>
        <v-btn color="success" v-if="!loading.getItem && isAuthorized( 'TrackingSheetEcology', 'store' )" :loading="saveProgress" @click.native="saveItem()" :disabled="(saveProgress || (dateValidDisabled && !objItem.csp_id) || isDeleted)">Enregistrer</v-btn>
        <v-btn color="primmary" @click.native="switchModal('compensationspecies', 0, 0)">Retour</v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import DatePicker from '@/components/ui/DatePicker'
import dateLocale from '../services/dateLocale';
import autoCompMulti    from './Autocomplete_multi.vue'
import { cacheGetters } from "../store/cache.module"
import { usrGetters }   from "../store/user.module"

export default {
    name: 'edit_compensation_spe',
    props: ['switchModal', 'objTrackingSheet', 'itemId'],
    data: function () {
        return {
            isAuthorized: usrGetters.getScopeRight,
            cardTitle: 'Compensation',
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            loading: { getItem: true },
            validForm   : true,
            dateValidDisabled : true,
            typeItem: cacheGetters.getTypes('SEC'),
            stateItem: cacheGetters.getStates('GES'),
            objItem: { 
                csp_id: this.itemId, csp_trs_id: this.objTrackingSheet.trs_id, csp_typ_id: 'SEC01', csp_sta_id: 'GES01',
                csp_spe_id: '', csp_sar_id: null, csp_wam_id: null, csp_value_estim: '', csp_value_sub: '', csp_value_valid: '', csp_date_valid_end: null,
                csp_value_nest: '', csp_value_rep: '', csp_value_food: '', csp_value_hib: '', 
                csp_value_trav: '', csp_value_resp: '', csp_linear_state: 0, csp_note: '', csp_date_valid:null
            },
            nameRules: [
                v => !!v || 'Ce champ est obligatoire',
                v => v.length <= 250 || 'Le champ doit avoir moins de 255 charactères'
            ],
            autoComplete: {
                csp_spe_id: null, 
                csp_sar_id: null,
                csp_wam_id: null,
                csp_was_id: null,
            },
            autoCompleteSource:{
                spe: [],
                sar: [],
                wam: [],
                was: [],
            },
            formLabelsByType:{
                'SEC01': { 
                    fieldName: 'Espèces végétales nom vernaculaire (ha)', n:null, r:null, a:null, h:null, d:null, rs:null,
                    info: '',
                    unit: 'Superficie',
                    linear_state: 0,
                },
                'SEC02': {
                    fieldName: 'Espèces animales nom vernaculaire (ha)', n:'Nidification', r:'Reproduction', a:'Alimentation', h:'Hibernation', d:'Déplacement', rs:'Repos',
                    info: '',
                    unit: 'Superficie',
                    linear_state: 0,
                },
                'SEC03': {
                    fieldName: 'Espèces animales nom vernaculaire (ml)', n:'Nidification', r:'Reproduction', a:'Alimentation', h:'Hibernation', d:'Déplacement', rs:'Repos',
                    info: '',
                    unit: 'Linéaire',
                    linear_state: 1,
                },
                'SEC04': {
                    fieldName: 'Espèces végétales autre', n:'', r:'', a:'', h:'', d:'', rs:'', other: 'Espèce autre',
                    info: '',
                    unit: 'Superficie',
                    linear_state: 0,
                },
                'SEC05': {
                    fieldName: 'Espèces animale autre', n:'', r:'', a:'', h:'', d:'', rs:'', other: 'Espèce autre',
                    info: '',
                    unit: 'Linéaire',
                    linear_state: 1,
                },
            },
            advices: [],
            isDeleted: false
        }
    },
    mounted:function (){
        if( this.itemId > 0){
            this.objItem['csp_id'] = this.itemId
            this.getItem()
            this.getAdvice()
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
        getItem(){
            return new Promise( (resolve, reject) => {
                this.items = []
                this.loading.getItem = true
                this.$http.get( '/compensationspecies/' + this.itemId + '/?include=species,smallagrregion,watermass,watershed,watersheadofwatermass&order_by=spe_name' ).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( typeof response.data.data[prop] === 'object' && response.data.data[prop] !== null && response.data.data[prop]['data'] !== undefined ){
                            this.objItem[prop] = response.data.data[prop]['data']
                        } else {
                            this.objItem[prop] = response.data.data[prop]
                        }
                    }
                    if( this.objItem.species ){
                        this.autoCompleteSource.spe.push(this.objItem.species)
                        this.autoComplete.csp_spe_id = this.objItem.csp_spe_id
                    }
                    if( this.objItem.smallagrregion ){
                        this.autoCompleteSource.sar.push(this.objItem.smallagrregion)
                        this.autoComplete.csp_sar_id = this.objItem.csp_sar_id
                    }
                    if( this.objItem.watermass ){
                        if( this.objItem.watersheadofwatermass ){
                            this.objItem.watermass['was_name'] =  this.objItem.watersheadofwatermass['was_name']
                        }
                        this.autoCompleteSource.wam.push(this.objItem.watermass)
                        this.autoComplete.csp_wam_id = this.objItem.csp_wam_id
                    }
                    if( this.objItem.watershed ){
                        this.autoCompleteSource.was.push(this.objItem.watershed)
                        this.autoComplete.csp_was_id = this.objItem.csp_was_id
                    }
                    if( this.objItem.csp_deleted_at ){
                        this.isDeleted = true
                    }
                    this.loading.getItem = false
                    resolve('resolved')
                }).catch( (error) => { 
                    this.loading.getItem = false
                    reject(error)
                })
            })
        },
        async saveItem() {
            this.objItem.csp_spe_id       = this.autoComplete.csp_spe_id || null
            this.objItem.csp_sar_id       = this.autoComplete.csp_sar_id || null
            this.objItem.csp_wam_id       = this.autoComplete.csp_wam_id || null
            this.objItem.csp_was_id       = this.autoComplete.csp_was_id || null
            this.objItem.csp_linear_state = this.formLabelsByType[ this.objItem.csp_typ_id ].linear_state || 0
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.itemId > 0 ){
                this.$http.put( '/compensationspecies/' + this.itemId, this.objItem ).then( () => {
                    const date1 = this.$date()
                    const date2 = this.$date(this.objItem.csp_date_valid_end)
                    const diff = date1.diff(date2, 'day')
                    if( diff > 0 ){
                        this.$http.delete( '/compensationspecies/' + this.objItem.csp_id + '/?csp_sta_id_state=GEN01' )
                    }
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('compensationspecies', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error })
                    this.switchModal('compensationspecies', 0, 1)
                })
            } else {
                this.$http.post( '/compensationspecies', this.objItem ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('compensationspecies', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ 
                        color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error 
                    })
                    this.switchModal('compensationspecies', 0, 0)
                });
            }
        },
        async saveItemReplace(){
            this.objItem.csp_spe_id       = this.autoComplete.csp_spe_id || null
            this.objItem.csp_sar_id       = this.autoComplete.csp_sar_id || null
            this.objItem.csp_wam_id       = this.autoComplete.csp_wam_id || null
            this.objItem.csp_was_id       = this.autoComplete.csp_was_id || null
            this.objItem.csp_linear_state = this.formLabelsByType[ this.objItem.csp_typ_id ].linear_state || 0
            
            await this.$http.put( '/compensationspecies/' + this.itemId, {csp_typ_id: this.objItem.csp_typ_id, csp_date_valid_end: this.objItem.csp_date_valid } )
            await this.$http.delete( '/compensationspecies/' + this.objItem.csp_id + '/?csp_sta_id_state=GEN01' ).then( () => {
                this.objItem.csp_id = 0
                this.objItem.csp_date_valid_end = null
                this.$http.post( '/compensationspecies', this.objItem ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('compensationspecies', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ 
                        color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error 
                    })
                    this.switchModal('compensationspecies', 0, 0)
                })
            })
        },
        deleteItem(){
            if( this.itemId > 0 ){
                this.$http.delete( '/compensationspecies/' + this.itemId ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée' })
                    this.switchModal('compensationspecies', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de la suppression !\n' + error })
                    this.switchModal('compensationspecies', 0, 1)
                })
            }
        },
        getAdvice(){
            return new Promise( (resolve, reject) => {
                this.advices = []
                this.$http.get( '/advice/?adv_trs_id=' + this.objTrackingSheet.trs_id ).then( (response) => {
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.advices.push(...response.data.data)
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getCacheType: cacheGetters.getTypes
    },
    components: {
        'auto-multi': autoCompMulti,
        DatePicker
    }
}
</script>