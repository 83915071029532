<template>
    <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" :persistent="options.persistent">
        <v-card>
        <v-toolbar dark :color="options.color" dense text>
            <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-alert v-show="!!message" class="pl-4 pr-4 mt-5 mb-5" border="left" colored-border type="info" elevation="1">
            {{ message }}
        </v-alert>
        <DatePicker v-if="options.addDate" :label="'Date'" :objectForm="form" fieldName='date'></DatePicker>
        <v-card-actions class="grey lighten-3">
            <v-spacer></v-spacer>
            <v-btn color="success darken-1"  @click.native="agree" :disabled="!validForm">Oui</v-btn>
            <v-btn color="orange"  @click.native="cancel">Non</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import DatePicker from '@/components/ui/DatePicker'

export default {
    components: { DatePicker },
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
            color: 'primary',
            width: 290,
            zIndex: 1200,
            persistent: true,
            addDate: false
        },
        form: {
            date: null
        }
    }),
    computed: {
        validForm () {
            let valid = true
            valid = (this.options.addDate && !this.form.date) ? false : valid
            return valid
        }
    },
    beforeUpdate(){
        //this.form.date = ''
    },
    methods: {
        open(title, message, options) {
            this.dialog = true
            this.title  = title
            this.message = message
            this.options = Object.assign(this.options, options)
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject  = reject
            })
        },
        agree() {
            if( this.options.addDate ){
                this.resolve( { date: this.form.date } )
            } else {
                this.resolve(true)
            }
            this.dialog = false
        },
        cancel() {
            this.resolve(false)
            this.dialog = false
        }
    }
}
</script>