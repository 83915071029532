/* *****************************
    Objet : Specification
******************************** */

export const spfObj = {
    data: function () {
        return {
            objSpecfication: {
                spf_id: 0, spf_trs_id:	this.objTrackingSheet.trs_id, spf_tcv_id: this.tcv_id, spf_typ_id: 'SPF01', spf_sta_id: 'GEN01',
                spf_cdr_id: 0, spf_date_start: null, spf_date_expiration: null, spf_freeze_date: 0,
                spf_typ_id_index : 'INR01', spf_update_value : null, spf_update_tax : '',
                spf_payment_direct : '', spf_payment_frequency : '', spf_payment_unit_freq : '', spf_invoice_date : '', spf_invoice_tax_value: null,
                spf_amount_ht: 0, spf_area: 0, spf_delegation:0, spf_custom_fields: {}, spf_order: 0
                
            },
            objSpecficationClone: {},
            listSpecification: [],
            metaSpecification: {},
        }
    },
    mounted: function (){
        this.objSpecficationClone = JSON.stringify( this.objSpecfication )
    },
    methods: {
        // Get specification
        getSpecificationShow(options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                if( options.length > 0 ){
                    options = '/' + options
                }
                this.$http.get( '/specification/' + this.objSpecfication.spf_id + options).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( this.objSpecfication[prop] !== undefined ){
                            this.objSpecfication[prop] = response.data.data[prop] 
                        }
                    }
                    this.objSpecfication.spf_custom_fields = (!this.objSpecfication.spf_custom_fields) ? {} : this.objSpecfication.spf_custom_fields
                    resolve(true)
                }).catch( () => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de lecture' })
                    reject(false)
                })
            })
        },
        getSpecificationIndex(page, perPage, options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                this.listTrackingSheet = []
                let searchCriteria = ''
                this.$http.get( '/specification/' + searchCriteria + options ).then( (response) => {
                    this.metaSpecification = response.data.meta
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.listSpecification = response.data.data
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        putSpecificationUpdate(){
            return new Promise( (resolve, reject) => {
                this.$http.put( '/specification/' + this.objSpecfication.spf_id, this.objSpecfication ).then( (response) => {
                    this.objSpecfication.spf_id = response.data.data.spf_id
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    resolve(true)
                }).catch( (error) => { 
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error })
                    reject(false)
                })
            })
        },
        postSpecificationStore(){
            return new Promise( (resolve, reject) => {
                this.$http.post( '/specification', this.objSpecfication ).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( this.objSpecfication[prop] !== undefined ){
                            this.objSpecfication[prop] = response.data.data[prop] 
                        }
                    }
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    resolve(true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error })
                    reject(false)
                })
            })
        },
        deleteobjSpecfication(){
            return new Promise( (resolve, reject) => {
                this.$http.delete( '/specification/' + this.objSpecfication.spf_id, this.objSpecfication ).then( () => {
                    this.objSpecfication.spf_id = 0
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        cleanobjSpecfication(){
            var SpecificationClone = JSON.parse( this.objSpecficationClone )
            var prop
            for( prop in SpecificationClone ){
                if( this.objSpecfication[prop] !== undefined ){
                    this.objSpecfication[prop] = SpecificationClone[prop]
                }
            }
        }
    }
}